import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IdentityService } from 'app/http/identity/identity.service';
import { ERROR_MESSAGES, ErrorConfig } from './error-page.types';
import { StorageService } from 'app/helpers/storage/storage.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {
  isLocationError = false;
  isServerError = false;
  isNotFound = false;
  currentError: ErrorConfig;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private identityService: IdentityService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.isLocationError = this.router.url.includes('location-error');
    this.isServerError = this.route.snapshot.queryParams['type'] === 'server-error';
    this.isNotFound = this.router.url.includes('not-found');

    if (this.isLocationError) {
      this.currentError = ERROR_MESSAGES['location'];
    } else if (this.isServerError) {
      this.currentError = ERROR_MESSAGES['server'];
    } else if (this.isNotFound) {
      this.currentError = ERROR_MESSAGES['notFound'];
    } else {
      this.currentError = ERROR_MESSAGES['permission'];
    }
  }

  redirectToBackPage() {
    if (this.isLocationError || this.isNotFound || this.isServerError) {
      const companyId = this.storageService.getCompanyId();

      // If it's a 404 error or no companyId exists, redirect to companies admin page
      if (this.isNotFound || !companyId) {
        this.router.navigate(['/admin/companies']);
        return;
      }

      // For other errors with valid companyId, redirect to playbooks
      this.router.navigate(['/app/playbooks']);
    } else {
      this.logout();
    }
  }

  logout() {
    this.identityService.logout();
  }

  get buttonClass(): string {
    return !this.isLocationError && !this.isServerError && !this.isNotFound
      ? 'login-button'
      : 'back-button';
  }
}

