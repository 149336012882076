import { inject } from '@angular/core';
import {
  HttpEvent,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpHandlerFn,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { IdentityService } from '../../http/identity/identity.service';
import { StorageService } from '../storage/storage.service';
import jwtDecode from 'jwt-decode';
import { ToastService } from 'app/service/toast.service';
import { IDecodedAccessToken } from 'app/http/identity/identity.models';
import { ConnectionService } from 'app/services/connection.service';

export const authInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const storageService = inject(StorageService);

  const accessToken = storageService.getAccessToken();
  if (accessToken) {
    const clonedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
      withCredentials: true,
    });
    return next(clonedRequest);
  } else {
    return next(request);
  }
};

export const unauthErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const identityService = inject(IdentityService);
  const storageService = inject(StorageService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (
        error instanceof HttpErrorResponse &&
        !(
          req.url.includes('v2/Auth/Login') ||
          req.url.includes('v2/Auth/RefreshToken')
        ) &&
        error.status === 401
      ) {
        const refreshToken = storageService.getRefreshToken();
        if (refreshToken) {
          return identityService.refreshToken(refreshToken).pipe(
            switchMap((refreshResult) => {
              storageService.setAccessToken(refreshResult.accessToken);
              storageService.setRefreshToken(refreshResult.refreshToken);
              storageService.setUserAndRole(
                jwtDecode<IDecodedAccessToken>(refreshResult.accessToken),
              );
              return next(
                req.clone({
                  headers: req.headers.set(
                    'x-access-token',
                    `${refreshResult.accessToken}`,
                  ),
                }),
              );
            }),
            catchError((error) => {
              if (error.status == 403 || error.status === 401) {
                identityService.logout();
              }
              return throwError(() => error);
            }),
          );
        } else {
          return throwError(() => error);
        }
      }

      return throwError(() => error);
    }),
  );
};

export const errorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const toastService = inject(ToastService);
  const router = inject(Router);
  const connectionService = inject(ConnectionService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 500) {
          const errorMessage =
            (error as any)?.error?.ResponseException?.ExceptionMessage ??
            (error as any).error?.error;

          if (errorMessage) {
            toastService.show(errorMessage, 'toast-danger');
          }
          else {
            router.navigate(['/error'], { queryParams: { type: 'server-error' } });
          }
        }

        if (error.status === 404) {
          router.navigate(['/not-found']);
        }

        if (error.status === 403) {
          if (!req.url.includes('v2/Auth/')) {
            router.navigate(['/error']);
          }
        }
      }

      return throwError(() => error);
    }),
  );
};

export const xsrfInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> => {
  const tokenExtractor = inject(HttpXsrfTokenExtractor);

  const clonedRequest = request.clone({
    setHeaders: {
      'X-XSRF-TOKEN': tokenExtractor.getToken() ?? '',
    },
    withCredentials: true,
  });
  return next(clonedRequest);
};
