export interface ErrorConfig {
  title: string;
  message: string;
  showLoginLink: boolean;
}

export const ERROR_MESSAGES: Record<string, ErrorConfig> = {
  location: {
    title: 'Page Not Found',
    message: 'Oops! The page you are attempting to access has been moved or deleted.',
    showLoginLink: false
  },
  server: {
    title: 'Server Error',
    message: 'Oops! Something went wrong on our end.\nPlease try again later or contact support if the issue persists.',
    showLoginLink: false
  },
  notFound: {
    title: 'Resource Not Found',
    message: "Oops! The page you're looking for doesn't exist.\nPlease check the URL or navigate back to home.",
    showLoginLink: false
  },
  permission: {
    title: 'Permission Denied',
    message: "Oops! Your account doesn't have access to this page.",
    showLoginLink: true
  }
};
